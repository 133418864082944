import React, { FunctionComponent } from "react";
import Translation from "../../../Translation";
import TestResultsPanel from "./TestResultsPanel";

const FoughtOffPanel: FunctionComponent = () => {
  return (
    <TestResultsPanel
      then={<Translation id="are-protected" />}
      should={<Translation id="talk-to-family" />}
    />
  );
};

export default FoughtOffPanel;
