import React, { FunctionComponent } from "react";

type TestResultsTabProps = {
  label: JSX.Element;
};
const TestResultsTab: FunctionComponent<TestResultsTabProps> = ({ label }) => {
  return (
    <div className="flex justify-between items-center w-full">
      <div className="span-green font-bold text-left pr-2">{label}</div>
      <div className="tab-indicator">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          viewBox="0 0 44 44"
        >
          <g fill="none" fillRule="evenodd" className="arrow">
            <circle cx="22" cy="22" r="21.5" />
            <polyline strokeWidth="3" points="11 16 22 28 33 16" />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default TestResultsTab;
