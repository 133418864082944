import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Video from "../../Video";

const AmIAtRisk: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      poster: file(base: { eq: "am-i-at-risk.png" }) {
        publicURL
      }
      videos: allFile(filter: { base: { eq: "Am_I_at_risk.mp4" } }) {
        nodes {
          ...VideoQuery
        }
      }
    }
  `);
  return <Video videos={data.videos} poster={data.poster.publicURL} />;
};

export default AmIAtRisk;
