import React, { FunctionComponent } from "react";
import Button from "../../../Button";
import Translation, { useTranslation } from "../../../Translation";
import TestResultsPanel from "./TestResultsPanel";

const NeverInfectedPanel: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <TestResultsPanel
      then={<Translation id="you-have-chb" />}
      should={<Translation id="talk-to-doctor-chb" />}
    >
      <div className="text-center my-8">
        <Button
          color="green"
          size="large"
          text={t("considering-treatment")}
          href="/considering-hep-b-treatment"
        />
      </div>
    </TestResultsPanel>
  );
};

export default NeverInfectedPanel;
