import React, { FunctionComponent } from "react";
import Button from "../../Button";
import Headline from "../../Headline";
import Translation, { useTranslation } from "../../Translation";
import AmIAtRisk from "../Videos/AmIAtRisk";

const RiskFactors: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <div id="risk-factors" className="chapter">
      <Headline>
        <h1>
          <Translation id="risk-factors" />
        </h1>
      </Headline>
      <div className="subject a">
        <h2>
          <Translation id="important-to-understand" />
        </h2>
        <div className="sub-section">
          <h3 className="text-green">
            <Translation id="risk-factors-include" />
          </h3>
          <div className="flex flex-col laptop:flex-row">
            <div className="w-full laptop:w-1/2 laptop:pr-10 ">
              <ul className="bullet leading-snug laptop:leading-relaxed">
                <li>
                  <Translation id="born-to-infected-mother" />
                </li>
                <li>
                  <Translation id="born-in-country" />
                </li>
                <li>
                  <Translation id="exposure-on-job" />
                </li>
                <li>
                  <Translation id="sexual-partners-with-hep-b" />
                </li>
                <li>
                  <Translation id="contact-with-bodily-fluids" />
                </li>
                <li>
                  <Translation id="shared-needles" />
                </li>
                <li>
                  <Translation id="razors-toothbrushes" />
                </li>
              </ul>
            </div>
            <div className="w-full laptop:w-1/2">
              <ul className="bullet mb-4">
                <li>
                  <Translation id="not-vaccinated-as-infant" />
                </li>
                <li>
                  <Translation id="living-with-partner" />
                </li>
                <li>
                  <Translation id="tattoo-piercing-unsterilized-tools" />
                </li>
              </ul>
              <AmIAtRisk analytics="Buttons,Clicked,Explainer video: Am I at Risk?" />
            </div>
          </div>
          <div className="text-center mt-4 mb-12">
            <Button
              color="green"
              size="large"
              text={t("learn-the-truth")}
              href="/living-with-hep-b/hep-b-facts"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskFactors;
