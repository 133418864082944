import React from "react";
import Article from "../../Article";
import RiskFactors from "./RiskFactors";
import GettingTested from "./GettingTested";
import TheResults from "./TheResults/TheResults";
import Vaccinate from "./Vaccinate";

const TestingAndDiagnosis = () => {
  return (
    <section id="hep-b-testing-diagnosis" className="main-section">
      <Article>
        <RiskFactors />
        <GettingTested />
        <TheResults />
        <Vaccinate />
      </Article>
    </section>
  );
};
export default TestingAndDiagnosis;
