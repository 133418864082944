import React, { FunctionComponent } from "react";
import { WildcardRouteProps } from "../../types";
import Layout from "../components/Layout/Layout";
import TestingAndDiagnosis from "../components/Markup/TestingAndDiagnosis/TestingAndDiagnosis";
import SEO from "../components/seo";

const childRoutes = [
  "risk-factors",
  "getting-tested",
  "hep-b-test-results",
  "hep-b-vaccine",
];

const TestingAndDiagnosisPage: FunctionComponent<WildcardRouteProps> = ({
  "*": slug,
}) => {
  return (
    <Layout slug={slug} childRoutes={childRoutes}>
      <SEO
        name={slug || "hep-b-testing-diagnosis"}
        baseName="hep-b-testing-diagnosis"
      />
      <TestingAndDiagnosis />
    </Layout>
  );
};
export default TestingAndDiagnosisPage;
