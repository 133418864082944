import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Image from "../../Image";
import Headline from "../../Headline";
import Translation, { useTranslation } from "../../Translation";
import HorizontalRule from "../../HorizontalRule";
import TranslatedImage from "../../TranslatedImage";

const GettingTested: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(
    graphql`
      query {
        testing: file(base: { eq: "lab.png" }) {
          ...MobileImage
        }
        antibody: file(base: { eq: "antibody.png" }) {
          ...SmallImage
        }
        months: allFile(filter: { base: { eq: "months.png" } }) {
          nodes {
            relativePath
            ...SmallImage
          }
        }
      }
    `
  );

  return (
    <div id="getting-tested" className="chapter">
      <Headline>
        <h2>
          <Translation id="getting-tested" />
        </h2>
      </Headline>
      <div className="px-4 laptop:px-8 pt-8">
        <h2>
          <Translation id="as-many-as-1.4-million" />
        </h2>
        <div className="sub-section">
          <p>
            <Translation id="simple-blood-test" />
          </p>
          <Image
            fluid={data.testing.childImageSharp.fluid}
            alt={t("blood-sent-to-lab")}
            className="mx-auto pt-8 pb-2 px-12 laptop:px-0 w-full laptop:w-1/2"
          />
          <div className="mx-auto w-full px-10 laptop:px-0 laptop:w-3/5 flex justify-between">
            <div className="w-1/2 mr-4 laptop:mx-4 text-center">
              <Translation id="blood-from-arm" />
            </div>
            <div className="w-1/2 ml-4 laptop:mx-4 text-center">
              <Translation id="sent-and-examined" />
            </div>
          </div>
        </div>
        <HorizontalRule />
      </div>
      <div className="px-4 laptop:px-8 mb-8">
        <div className="flex flex-col laptop:flex-row">
          <div className="sub-section antibody pt-4">
            <div className="flex items-center">
              <div className="mr-4 laptop:hidden">
                <Image
                  fluid={data.antibody.childImageSharp.fluid}
                  className="w-20"
                  alt={t("surface-antibody-alt")}
                />
              </div>
              <h3>
                <Translation id="surface-antibody" />
              </h3>
            </div>
            <div className="flex">
              <div className="mr-4 hidden laptop:block">
                <Image
                  fluid={data.antibody.childImageSharp.fluid}
                  className="w-40"
                  alt={t("surface-antibody-alt")}
                />
              </div>
              <div className="laptop:mr-4">
                <p>
                  <Translation id="made-by-immune-system" />
                </p>
                <p className="span-nowrap">
                  <Translation id="protected-if-you-have" />
                </p>
              </div>
            </div>
          </div>
          <div className="sub-section antigen pt-4 laptop:pl-4">
            <div className="flex items-center">
              <div className="mr-4 laptop:hidden">
                <TranslatedImage
                  images={data.months}
                  className="w-20"
                  alt={t("surface-antigen-alt")}
                />
              </div>
              <h3>
                <Translation id="hepatitis-surface-antigen" />
              </h3>
            </div>
            <div className="flex">
              <div className="mr-4 hidden laptop:block">
                <TranslatedImage
                  images={data.months}
                  className="w-48"
                  alt={t("surface-antigen-alt")}
                />
              </div>
              <div className="laptop:mr-4">
                <p className="span-nowrap">
                  <Translation id="infected-if-found" />
                </p>
                <p className="span-nowrap">
                  <Translation id="if-in-body-chronic" />
                </p>
                <p>
                  <Translation id="test-may-be-repeated" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GettingTested;
