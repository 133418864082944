import React, { FunctionComponent } from "react";
import Headline from "../../../Headline";
import Tabcordion from "../../../Tabcordion/Tabcordion";
import Translation from "../../../Translation";
import ChronicPanel from "./ChronicPanel";
import FoughtOffPanel from "./FoughtOffPanel";
import NeverInfectedPanel from "./NeverInfectedPanel";
import TestResultsTab from "./TestResultsTab";
import VaccinatedPanel from "./VaccinatedPanel";

const TheResults: FunctionComponent = () => {
  const tabs = [
    <TestResultsTab label={<Translation id="never-been-infected" />} />,
    <TestResultsTab
      label={
        <Translation id="received-the-vaccination-and-develop-antibodies" />
      }
    />,
    <TestResultsTab label={<Translation id="were-infected" />} />,
    <TestResultsTab
      label={<Translation id="were-infected-did-not-fight-off" />}
    />,
  ];
  const panels = [
    <NeverInfectedPanel />,
    <VaccinatedPanel />,
    <FoughtOffPanel />,
    <ChronicPanel />,
  ];
  return (
    <div id="hep-b-test-results" className="chapter">
      <Headline>
        <h2>
          <Translation id="the-results" />
        </h2>
      </Headline>
      <div className="px-4 laptop:px-8 pt-4 pb-16">
        <h2>
          <Translation id="what-do-test-results-mean" />
        </h2>
        <p>
          <Translation id="results-tell-doctor" />
        </p>
        <div className="shadow-xl mt-12">
          <div className="bg-green text-white uppercase text-xl px-8 py-4">
            <Translation id="if-you" />
          </div>
          <Tabcordion
            tabs={tabs}
            panels={panels}
            initialPanel={-1}
            expandBreakpoint="never"
            className="tabcordion--results"
            collapsable
          />
        </div>
      </div>
    </div>
  );
};

export default TheResults;
