import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Headline from "../../Headline";
import HorizontalRule from "../../HorizontalRule";
import Image from "../../Image";
import TranslatedImage from "../../TranslatedImage";
import Translation, { useTranslation } from "../../Translation";

const Vaccinate: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      needle: allFile(filter: { base: { eq: "needle.png" } }) {
        nodes {
          relativePath
          ...DesktopImage
        }
      }
      shots: allFile(filter: { base: { eq: "shots.png" } }) {
        nodes {
          relativePath
          ...DesktopImage
        }
      }
      map: file(base: { eq: "map.svg" }) {
        publicURL
      }
      caution: file(base: { eq: "icon.png" }) {
        publicURL
      }
    }
  `);
  return (
    <div id="hep-b-vaccine" className="chapter">
      <Headline>
        <h2>
          <Translation id="vaccinate" />
        </h2>
      </Headline>
      <div className="px-4 pt-8 laptop:px-8">
        <h2>
          <Translation id="if-you-do-not-have" />
        </h2>
        <div className="mb-8">
          <h3 className="text-green text-base">
            <Translation id="vaccine-is-safe" />
          </h3>
          <p>
            <Translation id="vaccine-available-since" />
          </p>
          <TranslatedImage
            images={data.needle}
            alt={t("effective-alt")}
            className="mb-8 w-full tablet:w-3/5 laptop:w-1/2 mx-auto"
          />
          <p className="span-red span-bold span-underline vie-underline-border">
            <Translation id="vaccine-is-either" />
          </p>
          <TranslatedImage
            images={data.shots}
            alt={t("vaccine-alt")}
            className="w-full laptop:w-3/5 mx-auto"
          />
          <p>
            <Translation id="talk-to-doctor-vaccine" />
          </p>
        </div>
        <HorizontalRule />
      </div>
      <div className="subject b">
        <div className="sub-section">
          <h3 className="text-green">
            <Translation id="who-should-be-vaccinated" />
          </h3>
          <div className="flex flex-col laptop:flex-row">
            <div className="w-full laptop:w-1/2 laptop:mr-8">
              <ul className="bullet">
                <li>
                  <Translation id="all-pregnant-women-at-risk" />
                </li>
                <li>
                  <Translation id="all-newborns-at-birth" />
                </li>
                <li>
                  <Translation id="adolescents-not-vaccinated" />
                </li>
                <li>
                  <Translation id="anyone-who-lives-with" />
                </li>
              </ul>
            </div>
            <div className="w-full laptop:w-1/2">
              <ul className="bullet">
                <li>
                  <Translation id="sexual-partner-with" />
                </li>
                <li>
                  <Translation id="liver-disease-esrd-or-hiv" />
                </li>
                <li>
                  <Translation id="healthcare-workers-exposed" />
                </li>
                <li>
                  <Translation id="travelers-to-countries" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="sub-section">
          <img
            src={data.map.publicURL}
            alt={t("countries-alt")}
            className="w-4/5 mx-auto"
          />
          <div className="text-center pt-4 pb-16">
            <img
              src={data.caution.publicURL}
              className="w-6 inline-block text-base"
            />

            <strong className="text-green font-xl">
              <Translation id="vaccines-will-not-work" />
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vaccinate;
